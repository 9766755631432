@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"






















































































































.carousel
  &__navigation
    display: flex
    justify-content: flex-end
    margin-bottom: $size-m

    .sas-button
      +space-inline($size-xs)

  &__container
    overflow: hidden

  &__pages
    display: grid
    grid-auto-flow: column
    transition: all $speed-slow
