@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"






























$gradient: linear-gradient(44.11deg, #234190 23.32%, #2B5999 88.96%)
$shape: url('~@/assets/student-header-shape.png')
$shape-place: top right no-repeat
$shape-place-m: top right / auto 70% no-repeat
$shape-place-l: top right / 100% 70% no-repeat

.home-header
  overflow: hidden
  background: $shape $shape-place, $gradient

  +mq-m--mf
    background: $shape $shape-place-m, $gradient

  +mq-l--mf
    background: $shape $shape-place-l, $gradient

  .sas-wrapper
    +flex-space-between

  &__text
    flex: 1 0 auto
    padding: $size-m 0

    +mq-xs--mf
      padding: $size-l 0

    +mq-s--mf
      padding: $size-xl 0

    &__title
      color: $color-ice
      margin-bottom: $size-xs

    &__subtitle
      color: #BEC8DF

  &__illustration
    height: 100%
    position: relative
    right: -64px

    +mq-l--mf
      right: 0
