@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"































































.subjects
  &__title
    margin-bottom: $size-m

::v-deep .carousel__navigation
  +mq-l--mf
    margin-top: -60px
