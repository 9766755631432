@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/variables";
@import "~@/styles/mixins";
@import "~@/styles/media";









































































































.skeleton {
  width: 333px;
  height: 172px;
}

.footer {
  background: url('~@/assets/extraActivities/syllabus-card-texture.png') no-repeat left center;
  text-align: right;
  min-height: 28px;
}
