@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"















































.box-cards-container
  margin: 0 auto
  width: 1032px
  max-width: 100%
  overflow: visible

  &--scroll
    padding-bottom: 9px
    overflow: auto

  +mq-xl
    width: auto

  &__scroll
    padding: 0 $size-s
    width: max-content
    display: flex

    +mq-s--mf
      padding: 0 $size-l

    +mq-m--mf
      padding: 0 40px

    +mq-xl--mf
      padding: 0

::v-deep.box-card
  flex-shrink: 0
  +space-inline($size-s)
