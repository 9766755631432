@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"



































































.extra-activities
  &__title
    margin-bottom: $size-m

  &__body
    overflow: auto

    &__cards
        padding: 0 $size-s
        width: max-content
        display: flex

  &__card
    margin-right: $size-s

    &:last-child
      margin-right: 0
