@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/variables";
@import "~@/styles/mixins";
@import "~@/styles/media";























































































.home {
  &__body {
    padding: {
      top: $size-l;
      bottom: $end-space;
    }
  }
}

.subjects {
  margin-top: $size-l;
}
