@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"
























































.box-card
  position: relative
  cursor: pointer
  width: 144px
  height: 164px
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  justify-content: space-between
  overflow: hidden
  margin-bottom: 6px

  +mq-xl--mf
    width: 160px
    height: 172px

  &::before
    +pseudo
    +cover(absolute)
    background: linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,0.4))

  &__title,
  &__picture
    z-index: 1

  &__title
    color: $color-white
