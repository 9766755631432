@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/variables";
@import "~@/styles/mixins";
@import "~@/styles/media";


































































































.card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 333px;
  height: 172px;
  overflow: hidden;
  margin-bottom: 6px;
  cursor: pointer;
  background-image: url('~@/assets/customAssignments/fake-door-card.svg') !important;
  background-repeat: no-repeat  !important;
  background-position: right bottom  !important;
}

.title {
  color: $color-main;
  font-size: $font-size-heading-5;
  font-weight: $font-weight-semi-bold;
}

.description {
  font-size: $font-size-s;
  display: block;
  padding-top: 13px;
  max-width: 180px;
  line-height: 1.4;
}

.action {
  display: inline-flex;
  align-items: center;
  margin-top: 12px;
  font-size: $size-s;
  gap: $size-xxs;
}
