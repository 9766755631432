@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"




























.slide-bullets
  +flex-center

  &__bullet
    background: #B8BED5
    width: 10px
    height: 10px
    border-radius: 10px
    +space-inline(12px)

    &.--active
      background: $color-primary
