@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/variables";
@import "~@/styles/mixins";
@import "~@/styles/media";















































.sas-button {
  margin: 0 $size-xs;

  @include mq-m {
    margin: $size-xs 0;
  }
}
