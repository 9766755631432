@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"














































































































































































































.student-onboarding
  z-index: 9999
  position: fixed

  &__illustrations
    position: relative
    overflow: hidden
    +flex-center

  &__overlay
    z-index: 9998
    +cover(fixed)
    +transition($speed-slow)
    background: rgba($color-ink, .50)

  &__content
    background-color: #003064
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: 1fr auto
    z-index: 99999
    +cover(fixed)
    top: $menu-height
    transition: background-color $speed-slow ease-in-out

    +mq-l--mf
      z-index: 9999
      position: fixed
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      height: 80%
      max-height: 640px
      width: max-content
      border-radius: 24px
      box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.25)
      overflow: hidden

  .skip
    z-index: 2
    position: absolute
    top: $size-s
    right: $size-s
    cursor: pointer
    color: $color-white
    background-color: transparent
    border: none
    +transition($speed-x-fast)
    +flex-center

    &:focus
      outline: none

    &__text
      font-size: $font-size-s
      font-weight: $font-weight-bold
      width: 108px
      margin-left: $size-xs / 2
      white-space: nowrap
      overflow: hidden

  .body
    background-color: $color-white
    padding: $size-m $size-m $size-s
    text-align: center
    position: relative
    display: flex
    flex-direction: column
    box-shadow: 0 -1px 12px rgba(0,0,0,.14)

    +mq-m--mf
      padding: 0
      margin-top: -24px
      z-index: 2

    &__text
      margin-bottom: $size-s
      min-height: 24vh
      display: flex
      flex-direction: column
      align-items: center

      +mq-m--mf
        padding: $size-m 170px 0

      +mq-l--mf
        padding: $size-l $size-l 0
        min-height: 138px
        margin-bottom: $size-l

      &__title
        margin-bottom: $size-s

      &__description
        line-height: 26px

        +mq-l--mf
          width: 500px

    .slide-bullets
      margin-bottom: $size-m

      +mq-m--mf
        margin-bottom: $size-l

    &__controls
      &__actions
        +flex-center
        .sas-button
          +space-inline($size-s)

      .sas-button
        width: 100%

      +mq-m--mf
        border-top: 1px solid transparentize($color-ink-lightest, 0.5)
        padding: $size-s $size-m
        +flex-space-between

        .sas-button
          width: auto

        .body__controls__action
          width: 200px

.slide-background
  opacity: 0.15
  width: 100%
  height: 100%
  +cover(absolute)
  object-fit: cover
  object-position: center

.slide-illustration
  position: absolute
  z-index: 1

