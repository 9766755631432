@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"












































.home-body-skeleton
  &__title
    margin-bottom: $size-m

  &__cards
    display: flex

  &__card
    +space-inline($size-s)
